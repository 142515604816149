import React from "react";

const Navigation = () => (
  <nav className="flex justify-around bg-gray-800 text-white py-4">
    <a
      href="https://bakes-n-cakes.ola.click/"
      className="hover:text-yellow-500"
    >
      CONTACT US
    </a>
    <a
      href="https://bakes-n-cakes.ola.click/products"
      className="hover:text-yellow-500"
    >
      MENU
    </a>
  </nav>
);

export default Navigation;
