import React from "react";
import logo from "../BNC-Logo-Transparent-BG.png"; // Replace with the actual path to your logo

const Header = () => (
  <header className="p-6 bg-yellow-500 text-center text-white">
    <div className="flex flex-col items-center">
      <img
        src={logo}
        alt="Bakes 'N' Cakes Logo"
        className="max-h-20 w-auto mb-4" // Auto width, responsive height, spacing below the logo
      />
      <p className="text-lg mt-2">Purely Eggless, Perfectly Delicious</p>
    </div>
  </header>
);

export default Header;
