import React from "react";
import Header from "./components/Header";
import Navigation from "./components/Navigation";
import InstagramSection from "./components/InstagramSection";
import Footer from "./components/Footer";

function App() {
  return (
    <div>
      <Header />
      <Navigation />
      <InstagramSection />
      <Footer />
    </div>
  );
}

export default App;
