import React, { useEffect, useState } from "react";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const InstagramCarousel = () => {
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);
  const [nextPage, setNextPage] = useState(null);
  const [loading, setLoading] = useState(false);

  const accessToken = process.env.REACT_APP_INSTAGRAM_ACCESS_TOKEN;
  const initialUrl = `https://graph.instagram.com/me/media?fields=id,permalink,media_url,caption&access_token=${accessToken}`;

  const fetchPosts = async (url) => {
    if (!url || loading) return;
    setLoading(true);
    try {
      const response = await axios.get(url);
      const { data, paging } = response.data;
      setPosts((prevPosts) => [...prevPosts, ...data]);
      setNextPage(paging?.next || null);
    } catch (err) {
      console.error("Error fetching Instagram posts:", err);
      setError("Failed to load Instagram feed.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPosts(initialUrl);
  }, [initialUrl]);

  useEffect(() => {
    const existingScript = document.querySelector(
      'script[src="//www.instagram.com/embed.js"]'
    );
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = "//www.instagram.com/embed.js";
      script.async = true;
      script.onload = () => {
        window.instgrm?.Embeds?.process();
      };
      document.body.appendChild(script);
    } else {
      window.instgrm?.Embeds?.process();
    }
  }, [posts]);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    customPaging: (i) => (
      <div
        style={{
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          background: "#ccc",
          margin: "0 5px",
        }}
      ></div>
    ),
    appendDots: (dots) => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
          overflowX: "auto",
        }}
      >
        {dots}
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    afterChange: (current) => {
      if (posts.length - current <= 5 && nextPage) {
        fetchPosts(nextPage);
      }
    },
  };

  return (
    <section id="instagram" className="p-6">
      <h2 className="text-2xl font-bold text-center mb-4">
        Latest from Instagram
      </h2>
      {error ? (
        <p className="text-center text-red-500">{error}</p>
      ) : (
        <div>
          <Slider {...settings}>
            {posts.map((post) => (
              <div key={post.id} className="instagram-slide">
                <blockquote
                  className="instagram-media"
                  data-instgrm-permalink={post.permalink}
                  data-instgrm-version="14"
                  style={{
                    background: "#FFF",
                    border: 0,
                    borderRadius: "3px",
                    boxShadow:
                      "0 0 1px 0 rgba(0,0,0,0.5), 0 1px 10px 0 rgba(0,0,0,0.15)",
                    margin: "1px auto",
                    maxWidth: "540px",
                    padding: 0,
                    width: "calc(100% - 2px)",
                  }}
                ></blockquote>
              </div>
            ))}
          </Slider>
          {loading && <p className="text-center mt-4">Loading more...</p>}
        </div>
      )}
    </section>
  );
};

export default InstagramCarousel;
